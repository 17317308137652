import Vue from 'vue'
import Vuex from 'vuex'
import getters from "./app/getters";
import mutations from "./app/mutations";

// Persisted Data Plugin
import createPersistedState from 'vuex-persistedstate'
import secureStorage from './secure-storage'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    menuActive: false,
    user: {},
    ruta: {},
  },
  getters,
  mutations,
  actions: {
  },
  modules: {
  },
  plugins: [
    createPersistedState({
      storage: {
        getItem: key => secureStorage.getItem(key),
        setItem: (key, value) => secureStorage.setItem(key, value),
        removeItem: key => secureStorage.removeItem(key),
      }
    })
  ]
})
