export default {
    setUser(state, user) {
      state.user = user
    },
    setMenuActive(state, menuActive) {
      state.menuActive = menuActive
    },
    setRuta(state, ruta) {
      state.ruta = ruta
    },
  };
  