export default {
    getUser(state) {
      return state.user;
    },
    getMenuActive(state) {
      return state.menuActive;
    },
    getRuta(state){
      return state.ruta;
    }
  };