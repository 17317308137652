import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index';

Vue.use(VueRouter)

const routes = [
  { path: '*', beforeEnter: (to, from, next) => { next('/404') } },
  {
      path: '/404',
      name: '404',
      component: () => import(/* webpackChunkName: "404" */'../views/pages/404.vue')
  },
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "SignIn" */ '../views/pages/SignIn.vue'),
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: '/system',
    name: 'system',
    component: () => import(/* webpackChunkName: "DashboardLayout" */ '../views/pages/DashboardLayout.vue'),
    children: [
      {
        path: '/solicitud-coe',
        name: 'solicitud-coe',
        component: () => import(/* webpackChunkName: "SolicitudCoe" */ '../views/pages/SolicitudCoe.vue'),
        meta: {
          requiresAuth: true,
          coeAuth: true,
          adminAuth: true,
          innovacion: false,
        }
      },
      {
        path: '/solicitante-profile/:id',
        name: 'solicitante-profile',
        component: () => import(/* webpackChunkName: "SolicitantesProfile" */ '../views/pages/SolicitantesProfile.vue'),
        meta: {
          requiresAuth: true,
          coeAuth: true,
          adminAuth: true,
          innovacion: false,
        }
      },
      {
        path: '/bolsa-de-trabajo',
        name: 'bolsa-de-trabajo',
        component: () => import(/* webpackChunkName: "BolsaTrabajo" */ '../views/pages/BolsaTrabajo.vue'),
        meta: {
          requiresAuth: true,
          coeAuth: true,
          adminAuth: false,
          innovacion: false,
        }
      },
      {
        path: '/eventos',
        name: 'eventos',
        component: () => import(/* webpackChunkName: "eventos" */ '../views/pages/innovacion/eventos.vue'),
        meta: {
          requiresAuth: true,
          coeAuth: false,
          adminAuth: false,
          innovacion: true,
        }
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  store.commit('setRuta', to.fullPath)
  // REQUIERE AUTENTICACIÓN
  // if (to.meta.requiresAuth) {
  //   // Revisamos si hay alguien en sesiom
  //   const isLoggedIn = store.getters.getUser
  //   // Si no hay nadie volvemos a la pagina de Login
  //   if (Object.keys(isLoggedIn).length === 0) {
  //     next('/')
  //   } else {
  //     // De lo contrario si existio un inicio de sesion y obtenemos el perfil
  //     const perfil = store.getters.getUser.IdPerfil
  //     next()
  //     // dependiendo del perfil autorizamos las rutas
  //     /* if ((to.meta.coeAuth && to.meta.adminAuth)){
  //       if (perfil === 3 || perfil === 2) {
  //         next()
  //       } else {
  //         next('/solicitud-coe')
  //       }
  //     } */
  //   }
  // } else {
  //   const isLoggedIn = store.getters.getUser
  //   if (Object.keys(isLoggedIn).length === 0) {
  //     next()
  //   } else {
  //     // De lo contrario si existio un inicio de sesion y obtenemos el perfil
  //     next('/solicitud-coe')
  //   }
  // }
  if (to.meta.requiresAuth) {
    // Revisamos si hay alguien en sesiom
    const isLoggedIn = store.getters.getUser
    // store.commit('setRuta', to)
    // Si no hay nadie volvemos a la pagina de Login
    if (Object.keys(isLoggedIn).length === 0) {
      next('/')
    } else {
      // De lo contrario si existio un inicio de sesion y obtenemos el perfil
      const perfil = store.getters.getUser.IdPerfil
      console.log(perfil);
      if(perfil == 1) {
        
      } else if (perfil == 2) {
        if(to.meta.adminAuth) {
          console.log('permiso concedido');
          next()
        } else {
          console.log('permiso no concedido');
          next('/solicitud-coe')
        }
      } else if (perfil == 3){
        if(to.meta.coeAuth) {
          console.log('permiso concedido');
          next()
        } else {
          console.log('permiso no concedido');
          next('/solicitud-coe')
        }
      } else if (perfil == 4){
        if(to.meta.innovacion) {
          console.log('permiso concedido');
          next()
        } else {
          console.log('permiso no concedido');
          next('/eventos')
        }
      }
    }
  } else {
    next()
  }
})

export default router
